import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import React from 'react'
import ReactDOM from 'react-dom'
import smoothscroll from 'smoothscroll-polyfill'

import { ROUTE } from 'definitions'

if (process.env.REACT_APP_ENV === 'production') {
  Sentry.init({
    dsn: 'https://d5026ecb307b4fc58c3f817836124bbb@o1174704.ingest.sentry.io/6272164',
    integrations: [new Integrations.BrowserTracing()],
    environment: process.env.REACT_APP_ENV,
    tracesSampleRate: 0.2,
  })
}

const App = React.lazy(() => import('./apps/eStoreApp'))
const MobileApp = React.lazy(() => import('./apps/MiniStore/v1/MobileApp'))
const MonitoringApp = React.lazy(() => import('./apps/MonitoringApp'))
const ReferralProgramApp = React.lazy(() => import('./apps/MiniStore/v1/ReferralProgramApp'))
const ReferralProgramAppV2 = React.lazy(() => import('./apps/MiniStore/v2/ReferralProgramApp'))

smoothscroll.polyfill()

const render = module.hot ? ReactDOM.render : ReactDOM.hydrate

render(
  <Router>
    <React.Suspense fallback={<div />}>
      <Switch>
        <Route path={ROUTE.MOBILE}>
          <MobileApp />
        </Route>
        <Route path={ROUTE.MONITORING}>
          <MonitoringApp />
        </Route>
        <Route path={ROUTE.MINISTORE_V2_REFERRAL}>
          <ReferralProgramAppV2 />
        </Route>
        <Route path={ROUTE.REFERRAL}>
          <ReferralProgramApp />
        </Route>
        <Route>
          <App />
        </Route>
      </Switch>
    </React.Suspense>
  </Router>,
  document.getElementById('root')
)
