export const SF_KEY = 'PEPLINK_STORE_FRONT'
export const SF_MINISTORE_KEY = 'PEPLINK_STORE_FRONT_MINISTORE'
export const SF_MINISTORE_REFERRAL_PROGRAM_KEY = 'PEPLINK_STORE_FRONT_MINISTORE_REFERRAL_PROGRAM'

export const ATTRIBUTE_MAP = {
  loadBalancingAndFailover: 'Load Balancing and Failover',
  pepvpn: 'PEPVPN',
}

export const ATTRIBUTE_HIDDEN = ['customFields', 'awCpDisablePrice']

export const ENV = {
  PRODUCTION: 'production',
}

export const AUTH_PROVIDER_KEY = 'AUTH_PROVIDER'
export const AUTH_PROVIDER = {
  TOKEN: 'token',
  KEYCLOAK: 'keycloak',
}

export const CREDIT_CARDS = {
  AMERICAN_EXPRESS: 'American Express',
  MASTERCARD: 'MasterCard',
  VISA: 'Visa',
  UNION_PAY: 'UnionPay',
}

export const MODAL_SIZE = {
  sm: '350px',
  md: '500px',
  lg: '700px',
}

export const ROUTE = {
  HOME: '/',
  LOGIN: '/login',
  ACCOUNT: '/account',
  ACCOUNT_SETTINGS: '/account/settings',
  ACCOUNT_ADDRESS_BOOK: '/account/address-book',
  ACCOUNT_BOOKMARK: '/account/bookmark',
  ACCOUNT_QUOTATION: '/account/quotation',
  ACCOUNT_DOWNLOAD: '/account/download',
  ACCOUNT_DEVICE_MANAGEMENT: '/account/device-management',
  CART: '/cart',
  CATEGORIES: '/categories',
  CHECKOUT: '/checkout',
  CHECKOUT_CONFIRM_ORDER: '/checkout/confirm-order',
  CHECKOUT_PAYMENT: '/checkout/payment',
  CHECKOUT_THANK_YOU: '/checkout/thank-you',
  ORDER: '/order',
  QUICK_ORDER: '/quick-order',
  PRODUCTS: '/products',
  INCONTROL_CALLBACK: '/incontrol/callback',
  SSO_CALLBACK: '/sso/callback',
  SEARCH: '/search',
  PASSWORD_RESET: '/password/reset',
  PARTNER_PAVILLION_CHECKOUT: '/partner-pavillion/checkout',
  QUOTATION_PAYMENT: '/quotation/payment',
  SN_REDIRECT: '/sn',
  SPEEDFUSION_CLOUD: '/speedfusioncloud',
  CHECK_LOGIN: '/check-login.html',
  MONITORING: '/monitoring',

  // MiniStore v1
  MOBILE: '/mobile',
  MOBILE_CHECKOUT: '/mobile/checkout',
  MOBILE_CHECKOUT_SUCCESS: '/mobile/checkout-success',
  MOBILE_PRODUCT_PLANS: '/mobile/product-plans',
  MOBILE_PRODUCT_PLANS_CART: '/mobile/product-plans/cart',
  MOBILE_PRODUCT_PLANS_CHECKOUT: '/mobile/product-plans/checkout',
  MOBILE_WARRANTIES: '/mobile/warranties',
  MOBILE_WARRANTIES_CART: '/mobile/warranties/cart',
  MOBILE_WARRANTIES_CHECKOUT: '/mobile/warranties/checkout',
  MOBILE_REFERRAL_REWARDS: '/mobile/referral-rewards',
  MOBILE_REFERRAL_REWARD_COUPONS: '/mobile/referral-reward-coupons',
  REFERRAL: '/m/referral',
  REFERRAL_SSO_CALLBACK: '/m/referral/sso/callback',
  REFERRAL_PRODUCTS: '/m/referral/products',
  REFERRAL_CART: '/m/referral/cart',
  REFERRAL_CHECKOUT: '/m/referral/checkout',
  REFERRAL_CHECKOUT_SUCCESS: '/m/referral/checkout-success',

  // MiniStore v2
  MINISTORE_V2_REFERRAL: '/m/v2/referral',
  MINISTORE_V2_REFERRAL_SSO_CALLBACK: '/m/v2/referral/sso/callback',
  MINISTORE_V2_REFERRAL_PRODUCTS: '/m/v2/referral/products',
  MINISTORE_V2_REFERRAL_CART: '/m/v2/referral/cart',
  MINISTORE_V2_REFERRAL_CHECKOUT: '/m/v2/referral/checkout',
  MINISTORE_V2_REFERRAL_CHECKOUT_SUCCESS: '/m/v2/referral/checkout-success',
}

export const PRODUCT_SECTION = {
  specification: 'specification',
  packageContent: 'package-content',
  download: 'download',
  customOptions: 'custom-options',
}

export const ESTORE_PATH = 'estore'

export const PO_TYPES = [
  {
    label: 'Submit Purchase Order No.',
    value: 'poNumber',
  },
  {
    label: 'Upload Purchase Order',
    value: 'poUpload',
  },
  {
    label: 'Provide by Email Later',
    value: 'poEmail',
  },
]

export const ORDER_STATUS = {
  ORDER_PROCESSING: 'Order Processing',
}

export const SHIPPING_METHOD = {
  CUSTOMER_PROVIDED: 'Customer Provided',
  PRIORITY_SHIPPING: 'Priority Shipping',
}

export const SHIPPING_CARRIER = {
  US_VFT_ONLY_SELF_PICK_UP: 'US VFT Only - self pick-up',
}

export const PAYMENT_METHODS = {
  bank: 'Bank Transfer',
  creditTerm: 'Peplink Credit Terms Payment',
  cc: 'Credit Card',
  // paypal: 'Paypal',
  paypalPayment: 'Paypal Payment',
  na: 'Not Available',
}

export const ACCOUNT_LINKS_DOWNLOAD = {
  SHIPPING_INFORMATION: 'ShippingInformation',
  PRODUCT_LIST: 'ProductList',
}

export const CONFIGURABLE_ATTRIBUTES = ['size']
export const CONFIGURABLE_PRODUCT = 'configurable product'

export const ERROR_CODES = {
  MUST_CHANGE_STORE: 'must_change_store',
}

export const SUBSCRIPTION_STATUS = {
  FAILED: 'FAILED',
}

export const REFERRAL_CODE = 'REFERRAL_CODE'
export const GET_REFERRAL_CODE = 'GET_REFERRAL_CODE'

export const SUBMITTING = 'SUBMITTING'

export const SSO_STATE = 'abcdweradfasd'

export const SFC_MANAGEMENT = 'SFC Management'
export const SFC_CONNECTIVITY = 'Connectivity Usage Record'

export const PROMPT_CLEAR_CART = 'PROMPT_CLEAR_CART'

// ATTRIBUTES
// ==========================================================================
export const ATTRIBUTE_NAMES = {
  VIRTUAL_PRODUCT_TYPE: 'virtualProductType',
}

export const VIRTUAL_PRODUCT_TYPE = {
  DATA_PLAN_LICENSE: 'Data Plan License',
  CLOUD: 'Cloud',
  CLOUD_CONNECT: 'Cloud Connect',
  CLOUD_UNLIMITED: 'Cloud Unlimited',
}

export const PARTNER_PAVILLION = {
  QUOTATIONS_URL: `${process.env.REACT_APP_PEPLINK_PARTNER_PAVILLION_URL}/co-term-quotations`,
  QUOTATIONS_THANK_YOU_URL: `${process.env.REACT_APP_PEPLINK_PARTNER_PAVILLION_URL}/co-term-quotations/success/thanks`,
}

export const WARRANTY_TYPE = {
  ESSENTIAL_CARE: 'Extended Warranty',
  ESSENTIAL_CARE_PLUS: 'EssentialCare+',
}

export const REGION = {
  GLOBAL: 'Global',
  US: 'US',
}
